/* eslint-disable */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {removeToken} from './removeToken';
import AuthContext from '../../../../AuthContext';

export class AppTopbar extends Component {

    static defaultProps = {
      onToggleMenu: null
    }

    static propTypes = {
      authToken: PropTypes.any,
      onToggleMenu: PropTypes.func.isRequired
    }

    constructor(props){
      super(props);
      this.state = {
        expanded: false
      };
      this.onClick = this.onClick.bind(this);
      this.onLogout = this.onLogout.bind(this);
    }

    onClick(event) {
      this.setState({expanded: !this.state.expanded});
      event.preventDefault();
    }

    onLogout(event) {
      this.context.logout();
      removeToken();
      event.preventDefault();
    }

    render() {

      let logoTopbar = 'assets/layout/images/logo-topbar.svg';

      return (
        <div className="layout-topbar clearfix">
          <a className="p-link layout-menu-button" onClick={this.props.onToggleMenu}>
            <span className="pi pi-bars"/>
          </a>
          <div className="layout-topbar-icons">
            <img alt="Logo" src={logoTopbar} />            
            <button className="p-link" onClick={this.onLogout}>
              <span className="layout-topbar-item-text">Logout</span>
              <span className="layout-topbar-icon pi pi-power-off"/>
            </button>
          </div>
        </div>
      );
    }
}

AppTopbar.contextType = AuthContext;

export default AppTopbar;