import React, { Component } from 'react';
import classNames from 'classnames';
import axios from 'axios';
import PropTypes from 'prop-types';
import AppTopbar from './AppTopbar';
import AppMenu from './AppMenu';
import AppInlineProfile from './AppInlineProfile';
import AllRoutes from './AllRoutes';
import AuthContext from '../../../../AuthContext';
import {removeToken} from './removeToken';

class App extends Component {

  static propTypes = {
    menus: PropTypes.any,
    rotas: PropTypes.any,
    loadMenu: PropTypes.any,
    authToken: PropTypes.any
  }

  constructor(props) {
    super(props);
    this.state = {
      layoutMode: 'static',
      layoutColorMode: 'dark',
      staticMenuInactive: false,
      overlayMenuActive: false,
      mobileMenuActive: false,
      menus: null,
      rotas: null,
      hasError: false,
      errorMsg: null,
      errorInfo: null,
      permissao: null
    };

    this.onWrapperClick = this.onWrapperClick.bind(this);
    this.onToggleMenu = this.onToggleMenu.bind(this);
    this.onSidebarClick = this.onSidebarClick.bind(this);
    this.onMenuItemClick = this.onMenuItemClick.bind(this);
    this.onLogout = this.onLogout.bind(this);
    this.createMenu();
  }

  async componentDidMount() {
    try {
      const response = await axios
        .get('/api-rc/seguranca/permissao/servico/empresas',{
          headers: {
            'uriservico': '/acesso-gerencial'
          }
        });
      this.setState({ permissao: response });
    } catch (error) {
      this.onLogout();
    }
  }

  onLogout(event) {
    this.context.logout();
    removeToken();
  }

  onWrapperClick() {
    if (!this.menuClick) {
      this.setState({
        overlayMenuActive: false,
        mobileMenuActive: false,
      })
    }

    this.menuClick = false;
  }

  onToggleMenu(event) {
    this.menuClick = true;

    if (this.isDesktop()) {
        if (this.state.layoutMode === 'overlay') {
            this.setState({
                overlayMenuActive: !this.state.overlayMenuActive
            });
        }
        else if (this.state.layoutMode === 'static') {
            this.setState({
                staticMenuInactive: !this.state.staticMenuInactive
            });
        }
    }
    else {
        const mobileMenuActive = this.state.mobileMenuActive;
        this.setState({
            mobileMenuActive: !mobileMenuActive
        });
    }
  
    event.preventDefault();
  }

  onSidebarClick(event) {
    this.menuClick = true;
  }

  onMenuItemClick(event) {
    if(!event.item.items) {
        this.setState({
            overlayMenuActive: false,
            mobileMenuActive: false
        })
    }
  }

  createMenu() {
    this.menu = [
        {label: 'Início', icon: 'pi pi-fw pi-home', command: () => {window.location = '#/'}},
        {label: 'Vendas - Lojas', icon: 'pi pi-fw pi-shopping-cart', command: () => {window.location = '#/consulta-venda-lojas'}},
        {label: 'Vendas - Postos', icon: 'pi pi-fw pi-tag', command: () => {window.location = '#/consulta-venda-postos'}}
    ];
}  

  addClass(element, className) {
    if (element.classList)
        element.classList.add(className);
    else
        element.className += ' ' + className;
  }

  removeClass(element, className) {
    if (element.classList)
        element.classList.remove(className);
    else
        element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
  }

  isDesktop() {
    return window.innerWidth > 1024;
  }

  componentDidCatch(error, info) {
    // Display fallback UI
    this.setState({ hasError: true, errorMsg: error, errorInfo: info });
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, info);
    // console.log(error);
    // console.log(info);
  }

  componentDidUpdate() {
    if (this.state.mobileMenuActive)
        this.addClass(document.body, 'body-overflow-hidden');
    else
        this.removeClass(document.body, 'body-overflow-hidden');
  }

  render() {

    let logo = 'assets/layout/images/logo.svg';

    const wrapperClass = classNames('layout-wrapper', {
      'layout-overlay': this.state.layoutMode === 'overlay',
      'layout-static': this.state.layoutMode === 'static',
      'layout-static-sidebar-inactive': this.state.staticMenuInactive && this.state.layoutMode === 'static',
      'layout-overlay-sidebar-active': this.state.overlayMenuActive && this.state.layoutMode === 'overlay',
      'layout-mobile-sidebar-active': this.state.mobileMenuActive
    });

    const sidebarClassName = classNames("layout-sidebar", {
        'layout-sidebar-dark': this.state.layoutColorMode === 'dark',
        'layout-sidebar-light': this.state.layoutColorMode === 'light'
    });

    let body;

    if (this.state.hasError) {
      // You can render any custom fallback UI
      body = <div>
        <h1>Ocorreu um erro na chamada da aplicação.</h1>
        <div style={{ display: "none" }}>{'Error Message: ' + this.state.errorMsg}</div>
        <div style={{ display: "none" }}>{'Error Info: ' + JSON.stringify(this.state.errorInfo)}</div>
      </div>;
    } else {
      body = <AllRoutes />;
    }


    return (
      <div className={wrapperClass} onClick={this.onWrapperClick}>
        <AppTopbar onToggleMenu={this.onToggleMenu} />

        <div ref={(el) => this.sidebar = el} className={sidebarClassName} onClick={this.onSidebarClick}>

            <div className="layout-logo">
              <img alt="Logo" src={logo} />
            </div>
            <AppInlineProfile />
            <AppMenu model={this.menu} onMenuItemClick={this.onMenuItemClick} />

        </div>

        <div className="layout-main">
          {body}
        </div>

        <div className="layout-mask"></div>
      </div>
    );
  }
}

App.contextType = AuthContext;

export default App;
