/*
 * Polyfills obrigatórios para o React 16 funcionar
 */
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from "react-dom";
// import registerServiceWorker from './registerServiceWorker';
import Keycloak from 'keycloak-js';
import Axios from 'axios';

import 'primereact/resources/themes/nova-light/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import '@fullcalendar/core/main.css';
import '@fullcalendar/daygrid/main.css';
import '@fullcalendar/timegrid/main.css';
import './layout/layout.scss';

import * as Const from './consts/constants';
import Root from './apps/menu/root';

/**
 * Multi-tenancy para o KC.
 */
export function isContemporanea() {
  return window.location.hostname.includes("maxcontemporanea")
  || window.location.hostname.includes("maxatacarejo")
  // || window.location.hostname.includes("localhost") // Teste local
  ;
}
export function isZonta() {
  return window.location.hostname.includes("zonta");
}
export function isHomologa() {
  return window.location.hostname.includes("homologa");
}
function getKcJson() {
  return isContemporanea() ? 'contemporanea.json' : 'condor.json';
}

if (isContemporanea()) {
  document.title = "Corporativo - Max Contemporânea Atacarejo";
  (function() {
    var link = document.querySelector("link[rel*='icon']") || document.createElement('link');
    link.type = 'image/x-icon';
    link.rel = 'shortcut icon';
    link.href = '/favicon2.ico';
    document.getElementsByTagName('head')[0].appendChild(link);
  })();
} else {
  document.title = "Corporativo - Condor Super Center Ltda";
  (function() {
    var link = document.querySelector("link[rel*='icon']") || document.createElement('link');
    link.type = 'image/x-icon';
    link.rel = 'shortcut icon';
    link.href = '/favicon1.ico';
    document.getElementsByTagName('head')[0].appendChild(link);
  })();
}


const kc = Keycloak(`/${getKcJson()}`);
const token = sessionStorage.getItem(Const.KEYCLOAK_TOKEN);
const refreshToken = sessionStorage.getItem(Const.KEYCLOAK_REFRESH_TOKEN);

kc
  .init({ onLoad: 'login-required', promiseType: 'native', token, refreshToken })
  .then(authenticated => {
    if (authenticated) {
      updateSessionStorage();
      ReactDOM.render(<Root authToken={kc} />, document.getElementById("root"));
    }
  });

Axios
  .interceptors
  .request
  .use(config => (
    kc.updateToken(30)
      .then(refreshed => {
        if (refreshed) {
          updateSessionStorage()
        }
        config.headers.Authorization = 'Bearer ' + kc.token;
        return Promise.resolve(config)
      })
      .catch(() => {
        kc.login();
      })
  ));

const updateSessionStorage = () => {
  sessionStorage.setItem(Const.KEYCLOAK_TOKEN, kc.token);
  sessionStorage.setItem(Const.KEYCLOAK_REFRESH_TOKEN, kc.refreshToken);
};

// registerServiceWorker();