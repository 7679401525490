/* eslint-disable */
import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import {removeToken} from './removeToken';
import AuthContext from '../../../../AuthContext';

class AppInlineProfile extends Component {

    static propTypes = {
      authToken: PropTypes.any
    }

    constructor(props) {
      super(props);
      this.state = {
        expanded: false
      };
      this.onClick = this.onClick.bind(this);
      this.onLogout = this.onLogout.bind(this);
    }

    onClick(event) {
      this.setState({expanded: !this.state.expanded});
      event.preventDefault();
    }

    onLogout(event) {
      this.context.logout();
      removeToken();
      event.preventDefault();
    }

    render() {
      return  (
        <div className="layout-profile">
          <button className="p-link layout-profile-link" onClick={this.onClick}>
            <span className="username">{this.context.tokenParsed.preferred_username}</span>
            <i className="pi pi-fw pi-cog"/>
            <br />
            <span className="username"> {this.context.tokenParsed.name}</span>
          </button>
          <ul className={classNames({'layout-profile-expanded': this.state.expanded})}>
            <li><button className="p-link" href="#/profile"><i className="pi pi-fw pi-user"/><span>Perfil</span></button></li>
            <li><button className="p-link" onClick={this.onLogout}><i className="pi pi-fw pi-power-off"/><span>Logout</span></button></li>
          </ul>
        </div>
      );
    }
}

AppInlineProfile.contextType = AuthContext;

export default AppInlineProfile;
