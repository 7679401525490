import React from 'react';
import { Link } from 'react-router-dom';
import AuthContext from '../../../../AuthContext';

export const menuStyle = {
  width: '100%',
};

class Dashboard extends React.Component {

    render() {
      return (
        <div className="p-grid p-fluid dashboard">
          <div className="p-col-12 p-lg-3">
            <div className="card summary">
              <Link to="/consulta-venda-lojas">
                <h2>Vendas - Lojas</h2>
              </Link>                  
            </div>
          </div>
          <div className="p-col-12 p-lg-3">
            <div className="card summary">
              <Link to="/consulta-venda-postos">
                <h2>Vendas - Postos</h2>              
              </Link>
            </div>
          </div>
        </div>
      )
    }
}

Dashboard.contextType = AuthContext;

export default Dashboard;
