import React, {Component} from 'react';
import {Route, Switch} from 'react-router';

import Dashboard from './Dashboard';

import Loadable from 'react-loadable';

function Loading(props) {
  if (props.error) {
    return <div>Error! <button onClick={props.retry}>Tente novamente</button></div>;
  } else if (props.pastDelay) {
    return <div>Carregando...</div>;
  } else {
    return null;
  }
}

const VendasLojas = Loadable({ loader: () => import('../../../vendas/lojas/vendas-ui/root'), loading: Loading });
const VendasPostosConsultaVendaPostos = Loadable({ loader: () => import('../../../vendas/postos/consulta-venda-postos/root'), loading: Loading });
export class AllRoutes extends Component {
  render() {
    return (
      <Switch>
        <Route path="/" exact component={Dashboard} />
        <Route path="/consulta-venda-lojas" component={VendasLojas} />
        <Route path="/consulta-venda-postos" component={VendasPostosConsultaVendaPostos} />
      </Switch>
    )
  }
}

export default AllRoutes;
