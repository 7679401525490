import React, { Component } from 'react';
import { HashRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import App from './menus/components/App';
import ScrollToTop from './ScrollToTop';

import AuthContext from '../../AuthContext';

class Root extends Component {

  static propTypes = {
    authToken: PropTypes.any
  }

  render() {

    return (
      <HashRouter>
        <ScrollToTop>
          <AuthContext.Provider value={this.props.authToken}>
            <App />
          </AuthContext.Provider>
        </ScrollToTop>
      </HashRouter>
    );
  }
}

export default Root;